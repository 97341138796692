import React, { useState } from "react";
import Line from "./Line";
import Architecture from "../Assets/ServicesImage1.svg";
import Design from '../Assets/Design.jpg';
import Landscape from '../Assets/Landscape.jpg';
import Interior from '../Assets/Interior.jpg'

const Services = () => {
  const [activeButton, setActiveButton] = useState(0);
  const [imageText, setImageText] = useState(
    "At the heart of everything we do is the human experience to feel. To love. To imagine. To wonder. To guide. To jest. To rule. To serve. To belong."
  );
  const [imageSrc, setImageSrc] = useState(Architecture);

  const handleButtonClick = (index) => {
    setActiveButton(index);
    switch (index) {
      case 0:
        setImageSrc(Architecture);
        setImageText("At the heart of everything we do is the human experience to feel. To love. To imagine. To wonder. To guide. To jest. To rule. To serve. To belong.");
        break;
      case 1:
        setImageSrc(Landscape);
        setImageText("Landscaping design encompasses the creation of outdoor spaces that blend aesthetics and functionality, enhancing the natural beauty of the surroundings.");
        break;
      case 2:
        setImageSrc(Interior);
        setImageText("Interior design focuses on creating harmonious, functional spaces within buildings, enhancing the quality of life through thoughtful layout and aesthetics.");
        break;
      case 3:
        setImageSrc(Design);
        setImageText("Design integrates creativity and innovation, transforming ideas into visually compelling and practical solutions across various disciplines, fostering engagement, inspiration, and collaboration.");
        break;
      default:
        break;
    }
  };

  return (
    <div className="light:bg-white dark:bg-black light:text-black dark:text-white min-h-screen pt-10 pb-10 xs:px-[30px]">
      <div data-aos="fade-left" data-aos-duration={1500}>
        <Line />
      </div>
      <h1 className="text-[48px] font-semibold font-space-grotesk mb-10 pl-[120px] pt-10 xs:text-[20px] xs:text-left xs:pl-5 xs:pt-5 xs:mb-5">
        Services
      </h1>
      <div data-aos="fade-right" data-aos-duration={1500}>
        <Line />
      </div>
      <div className="px-[120px] flex flex-row justify-between mt-20 xs:flex-col xs:px-0 xs:mt-5 xs:items-center">
        <div
          className="flex flex-col items-center mb-10 mt-[150px] ml-[30px] xs:hidden"
          data-aos="fade-up"
        >
          <p className="font-space-grotesk font-bold text-[300px] leading-none text-center xs:text-[100px]">
            5
          </p>
          <p className="font-space-grotesk font-bold text-[36px] text-center xs:text-[24px]">
            Years of architecture, <br /> planning, design
          </p>
        </div>
        <div className="flex flex-row items-start mr-[40px] space-x-10 xs:flex-col xs:items-center xs:space-x-0 xs:mr-0">
          <div
            className="flex flex-col justify-center items-end mt-[120px] xs:flex-row xs:justify-center xs:items-center xs:mt-4"
            data-aos="fade-down"
          >
            <button
              className={`mb-20 p-2 bg-transparent text-right text-black dark:text-white text-[24px] xs:text-[15px] font-space-grotesk font-bold ${
                activeButton === 0 ? "underline" : ""
              } xs:mb-0`}
              onClick={() => handleButtonClick(0)}
            >
              Architecture
            </button>
            <button
              className={`mb-20 p-2 bg-transparent text-right text-black dark:text-white text-[24px] xs:text-[15px] font-space-grotesk font-bold ${
                activeButton === 1 ? "underline" : ""
              } xs:mb-0`}
              onClick={() => handleButtonClick(1)}
            >
              Landscape
            </button>
            <button
              className={`mb-20 p-2 bg-transparent text-right text-black dark:text-white text-[24px] xs:text-[15px] font-space-grotesk font-bold ${
                activeButton === 2 ? "underline" : ""
              } xs:mb-0`}
              onClick={() => handleButtonClick(2)}
            >
              Interior
            </button>
            <button
              className={`mb-20 p-2 bg-transparent text-right text-black dark:text-white text-[24px] xs:text-[15px] font-space-grotesk font-bold ${
                activeButton === 3 ? "underline" : ""
              } xs:mb-0`}
              onClick={() => handleButtonClick(3)}
            >
              Design
            </button>
          </div>
          <div
            className="flex flex-col justify-start items-center w-[560px] xs:w-[335px] xs:items-center xs:mt-4"
            data-aos="fade-left"
            data-aos-duration={1500}
          >
            <div className="mb-2">
              <img
                src={imageSrc}
                alt="Service"
                className="h-[640px] w-[560px] object-cover xs:h-[380px] xs:w-[335px]"
              />
              <div
                className="hidden xs:flex flex-row items-center xs:ml-0 xs:mt-5 xs:items-center xs:-mb-5"
                data-aos="fade-up"
              >
                <p className="font-space-grotesk font-bold text-[300px] leading-none text-center xs:text-[150px] pr-5">
                  5
                </p>
                <p className="font-space-grotesk font-bold text-[36px] text-left xs:text-[28px]">
                  Years of <br /> architecture, <br /> planning, design
                </p>
              </div>
            </div>
            <p
              data-aos="fade-up"
              className="text-lg text-center mt-4 xs:w-full font-space-grotesk"
            >
              {imageText}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
