import React, { useEffect, useState, useRef } from "react";
import FacebookLight from "../Assets/Section1FacebookLight.svg";
import InstagramLight from "../Assets/Section1InstagramLight.svg";
import InLight from "../Assets/Section1InLight.svg";
import FacebookDark from "../Assets/Section1FacebookDark.svg";
import InstagramDark from "../Assets/Section1InstagramDark.svg";
import InDark from "../Assets/Section1InDark.svg";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";

const BASE_URL = "https://mark-office.tframe.de";

const InsideProjectMain = ({ theme }) => {
  const openLink = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const [project, setProject] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 500);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const selectedProject = JSON.parse(localStorage.getItem("selectedProject"));
    setProject(selectedProject);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 500);
      console.log("Resizing, isMobile:", window.innerWidth < 500);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const container = scrollContainerRef.current;

    if (!isMobile && container) {
      const handleWheel = (e) => {
        if (e.deltaY !== 0) {
          e.preventDefault();
          container.scrollLeft += e.deltaY;
        }
      };

      container.addEventListener("wheel", handleWheel);

      return () => {
        container.removeEventListener("wheel", handleWheel);
      };
    }
  }, [isMobile, project]);

  if (!project) {
    return <div>Loading...</div>;
  }

  const images = project.attributes.images?.data || [];

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="bg-white dark:bg-black text-black dark:text-white h-[76.7vh] xs:h-auto xs:mb-14 flex xs:flex-col overflow-hidden">
      <div className="hidden xs:flex flex-col mx-[30px] mt-5">
        <h1 className="font-space-grotesk">
          {project.attributes.title} | Location: {project.attributes.location}
          <br />
          Investor: {project.attributes.investor}
        </h1>
      </div>
      <div className="fixed h-full w-[120px] flex flex-col justify-between items-center xs:w-[90px] xs:hidden">
        <div className="text-[20px] font-space-grotesk font-semibold transform mt-20 -rotate-90 origin-center py-5 text-center">
          <div className="inline-block">
            <span className="block whitespace-nowrap">
              {project.attributes.title} | Location:{" "}
              {project.attributes.location}
            </span>
            <span className="block whitespace-nowrap">
              Investor: {project.attributes.investor}
            </span>
          </div>
        </div>
        <div className="flex flex-col space-y-2 absolute bottom-60 mb-4">
          {theme === "dark" ? (
            <>
              <div data-aos="fade-up" data-aos-delay={1500}>
                <button
                  onClick={() =>
                    openLink("https://www.facebook.com/markofficeks")
                  }
                >
                  <img src={FacebookDark} alt="Facebook" />
                </button>
              </div>
              <div data-aos="fade-up" data-aos-delay={900}>
                <button
                  onClick={() =>
                    openLink("https://www.instagram.com/markoffice_")
                  }
                >
                  <img src={InstagramDark} alt="Instagram" />
                </button>
              </div>
              <div data-aos="fade-up" data-aos-delay={300}>
                <button
                  onClick={() =>
                    openLink("https://www.linkedin.com/company/mark-office")
                  }
                >
                  <img src={InDark} alt="LinkedIn" />
                </button>
              </div>
            </>
          ) : (
            <>
              <div data-aos="fade-up" data-aos-delay={1500}>
                <button
                  onClick={() =>
                    openLink("https://www.facebook.com/markofficeks")
                  }
                >
                  <img src={FacebookLight} alt="Facebook" />
                </button>
              </div>
              <div data-aos="fade-up" data-aos-delay={900}>
                <button
                  onClick={() =>
                    openLink("https://www.instagram.com/markoffice_")
                  }
                >
                  <img src={InstagramLight} alt="Instagram" />
                </button>
              </div>
              <div data-aos="fade-up" data-aos-delay={300}>
                <button
                  onClick={() =>
                    openLink("https://www.linkedin.com/company/mark-office")
                  }
                >
                  <img src={InLight} alt="LinkedIn" />
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <div
        ref={scrollContainerRef}
        className="flex-1 ml-[120px] overflow-x-auto xs:overflow-y-auto xs:ml-0 xs:mt-4 scroll-container"
      >
        <div className="flex flex-row gap-6 xs:flex-col">
          {images.map((image, index) => (
            <div
              className="mb-6 flex-shrink-0 h-[70vh] xs:h-auto xs:mx-[20px] cursor-pointer"
              key={index}
              onClick={() =>
                handleImageClick(`${BASE_URL}${image.attributes.url}`)
              }
            >
              <img
                src={`${BASE_URL}${image.attributes.url}`}
                alt={`Image ${index + 1}`}
                className="lazyload w-auto h-full object-cover xs:w-full xs:h-auto"
              />
            </div>
          ))}
        </div>
      </div>

      {selectedImage && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-80 flex items-center justify-center">
          <div className="relative">
            <button
              className="absolute top-0 right-0 mt-4 mr-4 text-6xl"
              style={{ color: "#39FF14" }}
              onClick={closeModal}
            >
              &times;
            </button>
            <img src={selectedImage} alt="Full size" className="max-h-screen" />
          </div>
        </div>
      )}
    </div>
  );
};

export default InsideProjectMain;
