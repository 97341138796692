import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./index.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Homepage from "./Pages/Homepage";
import DiscoverAll from "./Pages/DiscoverAll";
import InsideProject from './Pages/InsideProject';

const App = () => {
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ? localStorage.getItem("theme") : "light"
  );

  const element = document.documentElement;
  useEffect(() => {
    if (theme === "dark") {
      element.classList.add("dark");
      localStorage.setItem("theme", "dark");
    } else {
      element.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
  }, [theme]);

  useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
    AOS.refresh();
  }, []);

  return (
    <Router>
      <div className="overflow-x-hidden">
        <Routes>
          <Route path="/" element={<Homepage theme={theme} setTheme={setTheme} />} />
          <Route path="/discover" element={<DiscoverAll theme={theme} setTheme={setTheme} />} />
          <Route path='/insideproject' element={<InsideProject theme={theme} setTheme={setTheme} />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
