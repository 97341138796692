import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../Components/Header";
import HeaderMobile from "../Components/HeaderMobile";
import Section1 from "../Components/Section1";
import Services from "../Components/Services";
import RecentProjects from "../Components/RecentProjects";
import ContactUs from "../Components/ContactUs";
import Footer from "../Components/Footer";

const Homepage = ({ theme, setTheme }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.scrollPosition) {
      window.scrollTo(0, location.state.scrollPosition);
    }
  }, [location]);

  return (
    <div>
      <div className="xs:hidden">
        <Header theme={theme} setTheme={setTheme} />
      </div>
      <div className="hidden xs:block">
        <HeaderMobile theme={theme} setTheme={setTheme} />
      </div>
      <Section1 theme={theme} setTheme={setTheme} />
      <Services theme={theme} setTheme={setTheme} />
      <RecentProjects theme={theme} setTheme={setTheme} />
      <ContactUs theme={theme} setTheme={setTheme} />
      <Footer theme={theme} setTheme={setTheme} />
    </div>
  );
};

export default Homepage;
