import React from "react";
import House1 from "../Assets/Section1House.svg";
import FacebookLight from "../Assets/Section1FacebookLight.svg";
import InstagramLight from "../Assets/Section1InstagramLight.svg";
import InLight from "../Assets/Section1InLight.svg";
import FacebookDark from "../Assets/Section1FacebookDark.svg";
import InstagramDark from "../Assets/Section1InstagramDark.svg";
import InDark from "../Assets/Section1InDark.svg";

const Section1 = ({ theme }) => {
  const openLink = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div className="relative light:bg-white light:text-black dark:bg-black dark:text-white h-[800px] px-[120px] py-10 xs:px-[30px] xs:items-center xs:h-[300px]">
      <div className="relative z-10" data-aos="fade-up">
        <h1 className="z-10 mt-0 text-left text-[100px] font-space-grotesk font-bold xs:text-[36px] xs:mb-4 xs:w-full">
          Architecture.
          <br />
          Planning.
          <br />
          Design.
        </h1>
      </div>
      <div
        className="z-0 absolute top-[228px] left-[0px] right-0 mx-[120px] h-[540px] bg-no-repeat xs:top-[110px] xs:mx-[30px] xs:h-[185px] xs:w-[370px]"
        data-aos="fade-up"
        style={{
          backgroundImage: `url(${House1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="dark:bg-black dark:opacity-30 w-full h-full"></div>
      </div>
      <div className="absolute top-[180px] right-[140px] flex space-x-6 xs:top-[320px] xs:right-[30px] xs:hidden z-20">
        {theme === "dark" ? (
          <>
            <div data-aos="fade-left" data-aos-delay={1500}>
              <button onClick={() => openLink("https://www.facebook.com/markofficeks")}>
                <img src={FacebookDark} alt="Facebook" />
              </button>
            </div>
            <div data-aos="fade-left" data-aos-delay={900}>
              <button onClick={() => openLink("https://www.instagram.com/markoffice_")}>
                <img src={InstagramDark} alt="Instagram" />
              </button>
            </div>
            <div data-aos="fade-left" data-aos-delay={300}>
              <button onClick={() => openLink("https://www.linkedin.com/company/mark-office")}>
                <img src={InDark} alt="LinkedIn" />
              </button>
            </div>
          </>
        ) : (
          <>
            <div data-aos="fade-left" data-aos-delay={1500}>
              <button onClick={() => openLink("https://www.facebook.com/markofficeks")}>
                <img src={FacebookLight} alt="Facebook" />
              </button>
            </div>
            <div data-aos="fade-left" data-aos-delay={900}>
              <button onClick={() => openLink("https://www.instagram.com/markoffice_")}>
                <img src={InstagramLight} alt="Instagram" />
              </button>
            </div>
            <div data-aos="fade-left" data-aos-delay={300}>
              <button onClick={() => openLink("https://www.linkedin.com/company/mark-office")}>
                <img src={InLight} alt="LinkedIn" />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Section1;
