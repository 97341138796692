import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Line from "./Line";
import DiscoverAllLight from "../Assets/RecentProjectsDiscoverLight.svg";
import DiscoverAllDark from "../Assets/RecentProjectsDiscoverDark.svg";
import LogoLight from "../Assets/RecentProjectsImageLogoLight.svg";
import LogoDark from "../Assets/RecentProjectsImageLogoDark.svg";

const API_KEY =
  "Bearer f183237c48d0741788937daf1eac15ba5f5cb8e924762ca42e29faee0bfc9dac3ef4baaabdc7e6a9c456f161767fbefdab0fc3392d23b8286f6184b750f158501c744aa4c4bfaef57f438d14dc6b9efce7416a3f631e50db953a06e580a83c8a1aa3adc0cf5206663e2851d384c7e3ed5d50d180a11495a5c1317cb78ce49f8e";
const API_URL =
  "https://mark-office.tframe.de/api/projects?populate=headerImage,images";

const RecentProjects = ({ theme }) => {
  const [projects, setProjects] = useState([]);
  const [columns, setColumns] = useState(2);
  const navigate = useNavigate();
  const logo = theme === "dark" ? LogoDark : LogoLight;
  const BASE_URL = "https://mark-office.tframe.de";

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(API_URL, {
          headers: { Authorization: API_KEY },
        });
        const projectsData = response.data.data || response.data;
        if (Array.isArray(projectsData)) {
          setProjects(projectsData);
        } else {
          console.error("Data is not an array:", projectsData);
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();

    const handleResize = () => {
      if (window.innerWidth < 640) {
        setColumns(1);
      } else if (window.innerWidth < 768) {
        setColumns(2);
      } else {
        setColumns(2);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNavigateD = () => {
    navigate("/discover");
    window.scrollTo(0, 0);
  };

  const handleNavigateP = (project) => {
    localStorage.setItem("selectedProject", JSON.stringify(project));
    navigate("/insideproject");
    window.scrollTo(0, 0);
  };

  return (
    <div className="light:bg-white dark:bg-black light:text-black dark:text-white pt-10">
      <div
        data-aos="fade-right"
        data-aos-duration={1500}
        className="w-full px-[30px]"
      >
        <Line />
      </div>
      <div className="flex justify-between items-center px-[120px] mb-10 mt-10 xs:px-[30px] xs:mb-8 xs:mt-8">
        <h2
          className="text-[48px] font-bold font-space-grotesk xs:text-[20px]"
          data-aos="fade-right"
        >
          Recent projects
        </h2>
        <button data-aos="fade-left" onClick={handleNavigateD}>
          {theme === "dark" ? (
            <img
              src={DiscoverAllDark}
              alt="Discover All Dark"
              className="object-fill xs:w-[140px] xs:h-[40px]"
            />
          ) : (
            <img
              src={DiscoverAllLight}
              alt="Discover All Light"
              className="object-fill xs:w-[140px] xs:h-[40px]"
            />
          )}
        </button>
      </div>
      <div
        data-aos="fade-left"
        data-aos-duration={1500}
        className="w-full px-[30px]"
      >
        <Line />
      </div>
      <div className="flex flex-wrap justify-between px-[120px] mt-10 xs:px-5 xs:flex-col">
        {projects.length > 0 ? (
          <div className="masonry-grid gap-6" style={{ columnCount: columns }}>
            {projects.map((project) => {
              const { id, attributes } = project;
              const headerImageUrl = `${BASE_URL}${attributes.headerImage?.data?.attributes?.url}`;
              return (
                <div
                  key={id}
                  className="masonry-item mb-6 cursor-pointer "
                  style={{ breakInside: "avoid" }}
                  onClick={() => handleNavigateP(project)}
                >
                  <div className="relative">
                    <img
                      src={headerImageUrl}
                      alt={attributes.title}
                      className="w-full h-auto cursor-pointer"
                      data-aos="fade-up"
                      data-aos-duration={500}
                    />
                    <img
                      src={logo}
                      alt="Logo"
                      className="absolute top-5 left-5 w-[40px] h-auto"
                      data-aos="fade-up"
                      data-aos-duration={500}
                    />
                  </div>
                  <h1
                    className="font-medium text-[px] font-space-grotesk mt-4"
                    data-aos="fade-up"
                    data-aos-duration={1000}
                  >
                    {attributes.title}
                    <br />
                    Location: {attributes.location}
                    <br />
                    <span className="font-normal">
                      Investor: {attributes.investor}
                    </span>
                  </h1>
                </div>
              );
            })}
          </div>
        ) : (
          <p>No projects available</p>
        )}
      </div>
    </div>
  );
};

export default RecentProjects;
