import React, { useEffect, useState } from "react";

const Footer = (theme, setTheme) => {
  return (
    <div
      className={`light:bg-white dark:bg-black light:text-black dark:text-white pb-10`}
    >
      <div className="flex flex-row justify-between text-[20px] font-space-grotesk px-[120px] xs:hidden">
        <h1>
          © Copywriting | All Rights Reserved{" "}
          <span className="font-bold">MarkOffice</span>
        </h1>
        <h1>Terms of Use | Privacy</h1>
      </div>
      <div className="hidden xs:flex flex-col justify-center text-[15px] font-space-grotesk px-[30px] space-y-6 items-center">
        <h1>Terms of Use | Privacy</h1>
        <div className="items-center flex flex-col">
          <h1>© Copywriting</h1>
          <h1>
            All Rights Reserved <span className="font-bold">MarkOffice</span>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Footer;
