import React from "react";
import HeaderDiscover from "../Components/HeaderDiscover";
import Discover from "../Components/Discover";
import HeaderMobile from "../Components/HeaderMobile";

const DiscoverAll = ({ theme, setTheme }) => {
  return (
    <div>
      <div className="xs:hidden">
        <HeaderDiscover theme={theme} setTheme={setTheme} />
      </div>
      <div className="hidden xs:block">
        <HeaderMobile theme={theme} setTheme={setTheme} />
      </div>
      <Discover theme={theme} setTheme={setTheme} />
    </div>
  );
};

export default DiscoverAll;
