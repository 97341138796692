import React from "react";
import Line from "./Line";
import FacebookLight from "../Assets/Section1FacebookLight.svg";
import InstagramLight from "../Assets/Section1InstagramLight.svg";
import InLight from "../Assets/Section1InLight.svg";
import FacebookDark from "../Assets/Section1FacebookDark.svg";
import InstagramDark from "../Assets/Section1InstagramDark.svg";
import InDark from "../Assets/Section1InDark.svg";

const ContactUs = ({ theme }) => {
  const inputClass =
    theme === "dark"
      ? "border border-white bg-black text-white"
      : "border border-black bg-white text-black";

  const openLink = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const logos =
    theme === "dark" ? (
      <>
        <button
          data-aos="fade-up"
          data-aos-duration={400}
          onClick={() => openLink("https://www.facebook.com/markofficeks")}
          className="mr-5 w-[24px] h-[24px] xs:mr-2"
        >
          <img src={FacebookDark} alt="Facebook" />
        </button>
        <button
          data-aos="fade-up"
          data-aos-duration={800}
          onClick={() => openLink("https://www.instagram.com/markoffice_")}
          className="mr-5 w-[24px] h-[24px] xs:mr-2"
        >
          <img src={InstagramDark} alt="Instagram" />
        </button>
        <button
          data-aos="fade-up"
          data-aos-duration={1200}
          onClick={() =>
            openLink("https://www.linkedin.com/company/mark-office")
          }
          className="w-[24px] h-[24px]"
        >
          <img src={InDark} alt="LinkedIn" />
        </button>
      </>
    ) : (
      <>
        <button
          data-aos="fade-up"
          data-aos-duration={400}
          onClick={() => openLink("https://www.facebook.com/markofficeks")}
          className="mr-5 w-[24px] h-[24px] xs:mr-2"
        >
          <img src={FacebookLight} alt="Facebook" />
        </button>
        <button
          data-aos="fade-up"
          data-aos-duration={800}
          onClick={() => openLink("https://www.instagram.com/markoffice_")}
          className="mr-5 w-[24px] h-[24px] xs:mr-2"
        >
          <img src={InstagramLight} alt="Instagram" />
        </button>
        <button
          data-aos="fade-up"
          data-aos-duration={1200}
          onClick={() =>
            openLink("https://www.linkedin.com/company/mark-office")
          }
          className="w-[24px] h-[24px]"
        >
          <img src={InLight} alt="LinkedIn" />
        </button>
      </>
    );

  return (
    <div className="light:bg-white dark:bg-black light:text-black dark:text-white pt-10 xs:px-[30px] xs:min-w-screen">
      <div data-aos="fade-right" className="w-full" data-aos-duration={1500}>
        <Line />
      </div>
      <div
        data-aos="fade-up"
        className="w-full xs:flex text-center mt-10 mb-10 xs:mt-8 xs:mb-8 xs:items-start"
      >
        <h1 className="font-space-grotesk font-semibold text-[48px] xs:text-[20px]">
          Start a project?
        </h1>
      </div>
      <div data-aos="fade-left" className="w-full" data-aos-duration={1500}>
        <Line />
      </div>

      <div className="flex flex-row justify-between mt-10 px-[120px] items-start xs:flex-col xs:px-[30px] xs:items-center">
        <div className="flex flex-col mr-5 w-[620px] xs:w-full xs:items-center xs:mr-0">
          <div className="flex flex-row justify-between space-x-4 xs:flex-col xs:items-start xs:w-[370px] xs:space-x-0">
            <div className="flex flex-col w-[280px] xs:w-[370px]">
              <label
                className="mt-10 font-space-grotesk text-[20px] font-semibold xs:text-left xs:w-full xs:mt-0"
                data-aos="fade-up"
                data-aos-duration={500}
              >
                Name
              </label>
              <input
                className={`p-2 h-[48px] w-full pl-5 ${inputClass}`}
                placeholder="Name"
                data-aos="fade-up"
                data-aos-duration={500}
              />
            </div>
            <div className="flex flex-col w-[280px] xs:w-[370px]">
              <label
                className="mt-10 font-space-grotesk text-[20px] font-semibold xs:text-left xs:w-full xs:mt-5"
                data-aos="fade-up"
                data-aos-duration={500}
              >
                Last Name
              </label>
              <input
                data-aos="fade-up"
                data-aos-duration={500}
                className={`p-2 h-[48px] w-full pl-5 ${inputClass}`}
                placeholder="Surname"
              />
            </div>
          </div>
          <div className="xs:w-[370px] mt-5">
            <label
              className="mt-10 font-space-grotesk text-[20px] font-semibold xs:text-left xs:w-full"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              Email Address
            </label>
            <input
              data-aos="fade-up"
              data-aos-duration={500}
              className={`p-2 h-[48px] w-full xs:w-[370px] pl-5 ${inputClass}`}
              placeholder="Email Address"
            />
          </div>
          <div className="xs:w-[370px] mt-5">
            <label
              className="mt-10 font-space-grotesk text-[20px] font-semibold xs:text-left xs:w-full"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              Number
            </label>
            <input
              data-aos="fade-up"
              data-aos-duration={500}
              className={`p-2 h-[48px] w-full pl-5 xs:w-[370px] ${inputClass}`}
              placeholder="Number"
            />
          </div>
          <div className="xs:w-[370px] mt-5">
            <label
              className="mt-10 font-space-grotesk text-[20px] font-semibold xs:text-left xs:w-full"
              data-aos="fade-up"
              data-aos-duration={500}
            >
              Message
            </label>
            <textarea
              data-aos="fade-up"
              data-aos-duration={500}
              className={`p-2 h-[120px] w-full xs:w-[370px] pl-5 xs:h-[150px] ${inputClass}`}
              placeholder="Leave us a message..."
            />
          </div>
          <div className="xs:w-[370px] mt-5 flex justify-center">
            <button
              className={`w-[200px] h-[40px] font-space-grotesk font-semibold dark:bg-black dark:text-white border border-black dark:border-white bg-white text-black hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black`}
            >
              Submit
            </button>
          </div>
        </div>
        <div className="flex flex-col ml-10 mt-10 xs:ml-0 xs:items-center xs:w-[370px]">
          <div
            className="border border-black h-[380px] w-[700px] mb-10 xs:w-full xs:h-auto xs:hidden"
            data-aos="fade-up"
            data-aos-duration={500}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2934.869038017398!2d21.1770556!3d42.659305599999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13549ecb3d515251%3A0xeceba5eaf13c197!2zNDLCsDM5JzMzLjUiTiAyMcKwMTAnMzcuNCJF!5e0!3m2!1sen!2s!4v1721812520522!5m2!1sen!2s"
              width="100%"
              height="100%"
              allowFullScreen=""
              loading="lazy"
              style={{ border: 0 }}
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Map"
            ></iframe>
          </div>
          <div className="flex flex-row justify-between items-end xs:space-x-20">
            <div className="flex flex-row xs:mb-4">{logos}</div>
            <div
              className="flex flex-col text-right xs:text-center"
              data-aos="fade-left"
              data-aos-duration={500}
            >
              <h1 className="font-space-grotesk text-[20px] font-semibold xs:text-[15px]">
                Phone: 049 855 843
              </h1>
              <h1 className="font-space-grotesk text-[20px] font-semibold xs:text-[15px]">
                E-mail: info@markoffice.al
              </h1>
            </div>
          </div>
          <div
            className="hidden xs:flex border border-black h-[380px] w-[700px] mt-5 xs:w-full xs:h-auto"
            data-aos="fade-up"
            data-aos-duration={500}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2934.869038017398!2d21.1770556!3d42.659305599999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13549ecb3d515251%3A0xeceba5eaf13c197!2zNDLCsDM5JzMzLjUiTiAyMcKwMTAnMzcuNCJF!5e0!3m2!1sen!2s!4v1721812520522!5m2!1sen!2s"
              width="100%"
              height="100%"
              allowFullScreen=""
              loading="lazy"
              style={{ border: 0 }}
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Map"
            ></iframe>
          </div>
        </div>
      </div>
      <div className="mt-10 pb-10">
        <Line />
      </div>
    </div>
  );
};

export default ContactUs;
