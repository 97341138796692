import React from "react";
import HeaderLogoLight from "../Assets/HeaderLogoLight.svg";
import HeaderLogoDark from "../Assets/HeaderLogoDark.svg";
import HeaderContactLight from "../Assets/HeaderContactLight.svg";
import HeaderContactDark from "../Assets/HeaderContactDark.svg";
import HeaderButtonLight from "../Assets/HeaderButtonLight.svg";
import HeaderButtontDark from "../Assets/HeaderButtonDark.svg";
import { useNavigate } from "react-router-dom";

const Header = ({ theme, setTheme }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/");
    window.scrollTo({
      top: 3750,
      behavior: 'smooth'  
    });
  };
  

  return (
    <div className="light:bg-white light:text-black dark:bg-black dark:text-white duration-300 min-w-screen h-[210px] ">
      <div className="flex justify-between px-[120px]">
        <div className="top-0 h-[210px] w-auto" data-aos="fade-down">
          <>
            {theme === "dark" ? (
              <img src={HeaderLogoDark} className="object-fill" />
            ) : (
              <img src={HeaderLogoLight} className="object-fill" />
            )}
          </>
        </div>
        <div
          className="flex justify-center space-x-8 h-[120px] items-center"
          data-aos="fade-left"
        >
          {theme === "dark" ? (
            <button
              onClick={() => setTheme("light")}
              className="text-2xl h-[30px] w-[50px]"
            >
              <img src={HeaderButtontDark} className="object-fill" />
            </button>
          ) : (
            <button
              onClick={() => setTheme("dark")}
              className="text-2xl h-[30px] w-[50px]"
            >
              <img src={HeaderButtonLight} className="object-fill" />
            </button>
          )}
          {theme === "dark" ? (
            <button onClick={handleNavigate}>
              <img src={HeaderContactDark} />
            </button>
          ) : (
            <button onClick={handleNavigate}>
              <img src={HeaderContactLight} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
