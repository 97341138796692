import React from "react";
import HeaderLogoLight from "../Assets/HeaderDiscoverLogoLight.svg";
import HeaderLogoDark from "../Assets/HeaderDiscoverLogoDark.svg";
import HeaderContactLight from "../Assets/HeaderContactLight.svg";
import HeaderContactDark from "../Assets/HeaderContactDark.svg";
import HeaderButtonLight from "../Assets/HeaderButtonLight.svg";
import HeaderButtonDark from "../Assets/HeaderButtonDark.svg";
import { useNavigate } from "react-router-dom";

const HeaderDiscover = ({ theme, setTheme }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/', { state: { scrollPosition: 6700 } });
  };
  const handleNavigateMain = () => {
    navigate('/');
    window.scrollTo(0, 0);
  };

  return (
    <div className="light:bg-white light:text-black dark:bg-black dark:text-white duration-300 min-w-screen h-[170px] flex items-center px-[120px] xs:px-[30px] xs:h-[100px]">
      <div className="flex flex-row justify-between items-center w-full">
        <div className="h-[30px] w-[160px]" data-aos="fade-right">
          <button onClick={handleNavigateMain}>

          {theme === "dark" ? (
            <img src={HeaderLogoDark} className="object-contain xs:scale-50" alt="Logo Dark" />
          ) : (
            <img src={HeaderLogoLight} className="object-contain xs:scale-50" alt="Logo Light" />
          )}
          </button>
        </div>
        <div className="flex justify-center items-center space-x-8 xs:space-x-0" data-aos="fade-left">
          {theme === "dark" ? (
            <button 
              onClick={() => setTheme("light")} 
              className="text-2xl h-[30px] w-[50px] xs:mr-1 xs:ml-0" 
            >
              <img src={HeaderButtonDark} className="object-fill xs:scale-50" alt="Theme Button Dark" />
            </button>
          ) : (
            <button 
              onClick={() => setTheme("dark")} 
              className="text-2xl h-[30px] w-[50px] xs:mr-1 xs:ml-0"
            >
              <img src={HeaderButtonLight} className="object-fill xs:scale-50" alt="Theme Button Light" />
            </button>
          )}
          {theme === "dark" ? (
            <button 
              onClick={handleNavigate} 
              className="xs:scale-50 xs:-ml-10"
            >
              <img src={HeaderContactDark} alt="Contact Us Dark" className="p-0" />
            </button>
          ) : (
            <button 
              onClick={handleNavigate} 
              className="xs:scale-50 xs:-ml-10"
            >
              <img src={HeaderContactLight} alt="Contact Us Light" className="p-0" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderDiscover;
