import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import FacebookLight from "../Assets/Section1FacebookLight.svg";
import InstagramLight from "../Assets/Section1InstagramLight.svg";
import InLight from "../Assets/Section1InLight.svg";
import FacebookDark from "../Assets/Section1FacebookDark.svg";
import InstagramDark from "../Assets/Section1InstagramDark.svg";
import InDark from "../Assets/Section1InDark.svg";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";

const API_KEY =
  "Bearer f183237c48d0741788937daf1eac15ba5f5cb8e924762ca42e29faee0bfc9dac3ef4baaabdc7e6a9c456f161767fbefdab0fc3392d23b8286f6184b750f158501c744aa4c4bfaef57f438d14dc6b9efce7416a3f631e50db953a06e580a83c8a1aa3adc0cf5206663e2851d384c7e3ed5d50d180a11495a5c1317cb78ce49f8e";
const API_URL =
  "https://mark-office.tframe.de/api/projects?populate=headerImage,images";
const BASE_URL = "https://mark-office.tframe.de";

const ProjectGallery = ({ theme }) => {
  const openLink = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [columns, setColumns] = useState(3);
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [displayedProjects, setDisplayedProjects] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(API_URL, {
          headers: { Authorization: API_KEY },
        });
        const projectsData = response.data.data || [];
        setProjects(projectsData);
        setDisplayedProjects(projectsData);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchProjects();

    const handleResize = () => {
      if (window.innerWidth < 640) {
        setColumns(1);
      } else if (window.innerWidth < 768) {
        setColumns(2);
      } else {
        setColumns(3);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (selectedFilter === "All") {
      setDisplayedProjects(projects);
    } else {
      setDisplayedProjects(
        projects.filter((project) => project.attributes.type === selectedFilter)
      );
    }
  }, [selectedFilter, projects]);

  const handleNavigateP = (project) => {
    localStorage.setItem("selectedProject", JSON.stringify(project));
    navigate("/insideproject");
    window.scrollTo(0, 0);
  };

  const toggleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const applyFilter = (filter) => {
    setSelectedFilter(filter);
    setFilterOpen(false);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="h-auto min-h-screen bg-white dark:bg-black text-black dark:text-white xs:min-h-screen xs:h-auto">
      <div className=" pt-5 flex xs:flex-col">
        <div className="hidden mx-[30px] xs:flex">
          <h1 className="text-[20px] font-space-grotesk" data-aos="fade-up">
            Discover all
          </h1>
        </div>
        <div className="fixed h-full w-[120px] flex flex-col justify-between items-center xs:w-[90px] xs:hidden">
          <h1 className="text-[20px] font-space-grotesk font-semibold transform -rotate-90 absolute top-12 left-0 origin-center py-5 xs:text-[10px]">
            Discover all
          </h1>
          <div className="flex flex-col space-y-2 absolute bottom-64 mb-4">
            {theme === "dark" ? (
              <>
                <div data-aos="fade-up" data-aos-delay={1500}>
                  <button
                    onClick={() =>
                      openLink("https://www.facebook.com/markofficeks")
                    }
                  >
                    <img src={FacebookDark} alt="Facebook" />
                  </button>
                </div>
                <div data-aos="fade-up" data-aos-delay={900}>
                  <button
                    onClick={() =>
                      openLink("https://www.instagram.com/markoffice_")
                    }
                  >
                    <img src={InstagramDark} alt="Instagram" />
                  </button>
                </div>
                <div data-aos="fade-up" data-aos-delay={300}>
                  <button
                    onClick={() =>
                      openLink("https://www.linkedin.com/company/mark-office")
                    }
                  >
                    <img src={InDark} alt="LinkedIn" />
                  </button>
                </div>
              </>
            ) : (
              <>
                <div data-aos="fade-up" data-aos-delay={1500}>
                  <button
                    onClick={() =>
                      openLink("https://www.facebook.com/markofficeks")
                    }
                  >
                    <img src={FacebookLight} alt="Facebook" />
                  </button>
                </div>
                <div data-aos="fade-up" data-aos-delay={900}>
                  <button
                    onClick={() =>
                      openLink("https://www.instagram.com/markoffice_")
                    }
                  >
                    <img src={InstagramLight} alt="Instagram" />
                  </button>
                </div>
                <div data-aos="fade-up" data-aos-delay={300}>
                  <button
                    onClick={() =>
                      openLink("https://www.linkedin.com/company/mark-office")
                    }
                  >
                    <img src={InLight} alt="LinkedIn" />
                  </button>
                </div>
              </>
            )}{" "}
          </div>
        </div>
        <div className="flex-1 ml-[120px] overflow-y-auto p-4 xs:mx-[10px]">
          <div className="masonry-grid gap-6" style={{ columnCount: columns }}>
            {displayedProjects.map((project, index) => {
              const { attributes } = project;
              const headerImageUrl = attributes.headerImage?.data?.attributes
                ?.url
                ? `${BASE_URL}${attributes.headerImage.data.attributes.url}`
                : null;

              return (
                <div
                  key={index}
                  className="masonry-item mb-6 cursor-pointer group"
                  style={{ breakInside: "avoid" }}
                  onClick={() => handleNavigateP(project)}
                >
                  {headerImageUrl && (
                    <img
                      src={headerImageUrl}
                      alt={`${attributes.title} Header`}
                      className="w-full h-auto"
                    />
                  )}
                  <div className="mt-2 p-2 rounded-lg text-black dark:text-white opacity-0 group-hover:opacity-100 transition-opacity xs:hidden">
                    <h2 className="text-xl font-semibold font-space-grotesk">
                      {attributes.title}
                    </h2>
                    <p className="text-sm font-space-grotesk">Location: {attributes.location}</p>
                    <p className="text-sm font-space-grotesk">Investor: {attributes.investor}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="fixed bottom-10 right-10 xs:bottom-5 xs:right-1/2 xs:transform xs:translate-x-1/2 bg-white dark:bg-black w-[280px] h-[60px]">
          <div className="flex flex-row justify-between items-center p-3">
            <div className="relative">
              <button
                className="w-[90px] h-[35px] bg-black text-white dark:bg-white dark:text-black font-space-grotesk"
                onClick={toggleFilter}
              >
                Filter
              </button>
              {filterOpen && (
                <div className="absolute right-0 bottom-full mb-2 w-[140px] bg-white text-black dark:bg-black dark:text-white shadow-lg font-space-grotesk">
                  {[
                    "All",
                    "Architecture",
                    "Landscape",
                    "Interior",
                    "Design",
                  ].map((filter) => (
                    <div
                      key={filter}
                      className="cursor-pointer font-space-grotesk px-4 py-2 hover:bg-gray-200 dark:hover:bg-gray-800"
                      onClick={() => applyFilter(filter)}
                    >
                      {filter}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <h1 className="text-sm font-space-grotesk">{selectedFilter}</h1>
            <h1>
              {displayedProjects.length}/{projects.length}
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectGallery;
