import React from 'react';

const Line = () => {
  return (
    <div className="mx-[50px] border-t-2 border-black dark:border-white xs:-mx-[10px]">
    </div>
  );
};

export default Line;
