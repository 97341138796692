import React, { useState } from "react";
import HeaderLogoLight from "../Assets/HeaderMobileLogoLight.svg";
import HeaderNavLight from "../Assets/HeaderMobileNavLight.svg";
import HeaderLogoDark from "../Assets/HeaderMobileLogoDark.svg";
import HeaderNavDark from "../Assets/HeaderMobileNavDark.svg";
import FacebookLight from "../Assets/Section1FacebookLight.svg";
import InstagramLight from "../Assets/Section1InstagramLight.svg";
import InLight from "../Assets/Section1InLight.svg";
import FacebookDark from "../Assets/Section1FacebookDark.svg";
import InstagramDark from "../Assets/Section1InstagramDark.svg";
import InDark from "../Assets/Section1InDark.svg";
import HeaderButtonLight from "../Assets/HeaderButtonLight.svg";
import HeaderButtonDark from "../Assets/HeaderButtonDark.svg";
import { useNavigate } from "react-router-dom";

const HeaderMobile = ({ theme, setTheme }) => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleNavigate = () => {
    navigate("/");
    window.scrollTo(0, 4600);
  };
  const handleNavigateMain = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };
  const getLogos = () => {
    return theme === "dark" ? (
      <>
        <a
          href="https://www.facebook.com/markofficeks"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={FacebookDark} alt="Facebook" className="w-[24px] h-[24px]" />
        </a>
        <a
          href="https://www.instagram.com/markoffice_"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={InstagramDark}
            alt="Instagram"
            className="mr-2 w-[24px] h-[24px]"
          />
        </a>
        <a
          href="https://www.linkedin.com/company/mark-office"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={InDark} alt="LinkedIn" className="w-[24px] h-[24px]" />
        </a>
      </>
    ) : (
      <>
        <a
          href="https://www.facebook.com/markofficeks"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={FacebookLight} alt="Facebook" className="w-[24px] h-[24px]" />
        </a>
        <a
          href="https://www.instagram.com/markoffice_"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={InstagramLight}
            alt="Instagram"
            className="mr-2 w-[24px] h-[24px]"
          />
        </a>
        <a
          href="https://www.linkedin.com/company/mark-office"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={InLight} alt="LinkedIn" className="w-[24px] h-[24px]" />
        </a>
      </>
    );
  };

  return (
    <div
      className={`w-full ${
        menuOpen ? "h-[210px]" : "h-[70px]"
      } px-[30px] flex flex-col justify-between items-center bg-white dark:bg-black text-black dark:text-white duration-300 pt-[50px]`}
    >
      <div className="w-full flex flex-row justify-between items-center">
        <button onClick={handleNavigateMain}>
          <img
            src={theme === "dark" ? HeaderLogoDark : HeaderLogoLight}
            alt="Logo"
          />
        </button>
        <button onClick={() => setMenuOpen(!menuOpen)}>
          <img
            src={theme === "dark" ? HeaderNavDark : HeaderNavLight}
            alt="Menu"
          />
        </button>
      </div>
      {menuOpen && (
        <div className="w-full bg-white dark:bg-black p-5">
          <div className="flex flex-col items-center">
            <button
              onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
              className="text-2xl mb-4"
            >
              <img
                src={theme === "dark" ? HeaderButtonDark : HeaderButtonLight}
                className="object-fill"
                alt="Theme Toggle Button"
              />
            </button>
            <div className="text-center mb-4">
              <button onClick={handleNavigate}>
                <h1 className="font-bold font-space-grotesk">Contact us</h1>
              </button>
            </div>
            <div className="flex justify-center space-x-4">
              {getLogos()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderMobile;
